import React, { useState } from "react";
import { Grid2, styled, Tab, Tabs, useTheme, type Theme } from "@mui/material";
import { HomePage } from "./HomePage";
import { AccommodationsPage } from "./AccommodationsPage";
import { SurveyPage } from "./SurveyPage";
import { Route, Routes, Link, useLocation } from "react-router-dom";

const StyledTab = styled(Link)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    fontSize: "0.8rem",
  },
}));

export function TopbarTabs() {
  const location = useLocation();
  const selectedTab = location.pathname;
  const theme = useTheme();
  return (
    <Grid2 container justifyContent="center" flexDirection="column">
      <Grid2 container alignItems="center" justifyContent="center">
        <Tabs
          variant="scrollable"
          value={selectedTab}
          TabIndicatorProps={{
            style: {
              background: theme.palette.divider,
            },
          }}
        >
          <Tab label="Home" value={"/"} component={StyledTab} to={"/"} />
          <Tab
            label="Accommodations"
            value={"/accommodations"}
            component={StyledTab}
            to="/accommodations"
          />
          <Tab
            label="Notice of intent"
            value="/survey"
            component={StyledTab}
            to="/survey"
          />
        </Tabs>
      </Grid2>

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/accommodations" element={<AccommodationsPage />} />
        <Route path="/survey" element={<SurveyPage />} />
      </Routes>
    </Grid2>
  );
}
