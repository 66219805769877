import {
  Grid2,
  Link,
  Typography,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Dialog,
  styled,
} from "@mui/material";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import Iconify from "./Iconify";

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  marginBottom: "12px",
}));

export function SurveyPage() {
  const [email, setEmail] = useState("");
  const [response, setResponse] = useState("other");
  const [submitMessage, setSubmitMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onSubmit = async () => {

    if (!email) {
      setSubmitMessage("Please fill in your email.");
      setIsDialogOpen(true);
      return;
    }

    setIsSubmitting(true);

    const res = await fetch(
      "https://intent-response-646286843763.us-central1.run.app",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          intent: response,
        }),
      }
    );

    setIsSubmitting(false);

    if (!res.ok) {
      const body = await res.json();
      if (body.error === "email not found") {
        setSubmitMessage(
          "Whoops. Email not found. Please use the email of the Save The Date receiver. Thanks."
        );
      }
    } else {
      setSubmitMessage("Submitted successfully! Thanks for letting us know.");
    }
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  let dialog = null;
  if (submitMessage) {
    dialog = (
      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <p style={{ padding: "10px" }}>{submitMessage}</p>
      </Dialog>
    );
  }

  return (
    <Grid2
      container
      alignItems="center"
      justifyContent="center"
      margin="16px 12px"
      padding={2}
    >
      <Grid2
        container
        size={{ xs: 12, sm: 8, lg: 6 }}
        spacing={4}
        direction="column"
        marginTop={2}
        alignItems="center"
      >
        {dialog}
        <span>
          <Typography variant="body1">
            This is not an official invitation response and doesn't hold you
            responsible to any decisions, but we would love to get your help
            with general numbers, so we can plan accordingly. Please read the{" "}
            <Link target="_self" to="/accommodations" component={RouterLink}>
              accommodations
              <Iconify icon="mdi:external-link" />
            </Link>{" "}
            section to know more about the on-site options.
          </Typography>
        </span>
        <TextField
          placeholder="Email"
          sx={{ width: "100%", maxWidth: "500px" }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          variant="outlined"
        />
        <FormControl>
          <Typography sx={{ fontSize: "1.5rem", textDecoration: "underline" }}>
            What are the vibes?
          </Typography>
          <RadioGroup
            value={response}
            onChange={(e) => setResponse(e.target.value)}
            name="radio-buttons-group"
          >
            <StyledFormControlLabel
              value="onsite-full"
              control={<Radio />}
              label="I think I'd stay 2-3 nights on-site"
            />
            <StyledFormControlLabel
              value="onsite-day"
              control={<Radio />}
              label="I might just come for the big day and stay 1 night on-site"
            />
            <StyledFormControlLabel
              value="nearby"
              control={<Radio />}
              label="I'll probably find my own rental in the area and pop into the resort when I feel like it"
            />
            <StyledFormControlLabel
              value="hotel"
              control={<Radio />}
              label="I'd like to stay in a hotel in Renfrew"
            />
            <StyledFormControlLabel
              value="none"
              control={<Radio />}
              label="I'll be there for the celebration day but wouldn't need accomodations"
            />
            <StyledFormControlLabel
              value="no-vote"
              control={<Radio />}
              label="Don't make me think this far in advance, I have no idea"
            />
            <FormControlLabel
              value="rejected"
              control={<Radio />}
              label="I'm already busy and probably won't be coming at all :("
            />
          </RadioGroup>
        </FormControl>
        <div>
          <Button variant="contained" onClick={onSubmit}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </Button>
        </div>
      </Grid2>
    </Grid2>
  );
}
