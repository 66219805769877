import { Icon, IconProps } from "@iconify/react";

function Iconify({ icon, width = 12, ...other }: IconProps) {
  return (
    <Icon
      icon={icon}
      width={width}
      height={width}
      style={{ marginLeft: "3px" }}
      {...other}
    />
  );
}

export default Iconify;
